import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_wapper = _resolveComponent("wapper")!

  return (_openBlock(), _createBlock(_component_wapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_select, {
        modelValue: _ctx.currentSceneType,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentSceneType) = $event)),
        placeholder: "Select Scene Type",
        size: "large"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scenes, (item) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              key: item,
              label: item,
              value: item
            }, null, 8, ["label", "value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"]),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.taskDefs[_ctx.currentSceneType]?.creationComponent), { key: _ctx.currentSceneType }))
    ]),
    _: 1
  }))
}